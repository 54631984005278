/**
 * Key-value pairs of spinner names and their frames.
 *
 * Copied from https://github.com/pavdmyt/yaspin/blob/master/yaspin/data/spinners.json
 */
export const SPINNERS = {
  dots: ["⠋", "⠙", "⠹", "⠸", "⠼", "⠴", "⠦", "⠧", "⠇", "⠏"],
  dots2: ["⣾", "⣽", "⣻", "⢿", "⡿", "⣟", "⣯", "⣷"],
  dots3: ["⠋", "⠙", "⠚", "⠞", "⠖", "⠦", "⠴", "⠲", "⠳", "⠓"],
  dots4: ["⠄", "⠆", "⠇", "⠋", "⠙", "⠸", "⠰", "⠠", "⠰", "⠸", "⠙", "⠋", "⠇", "⠆"],
  line: ["-", "\\", "|", "/"],
  line2: ["⠂", "-", "–", "—", "–", "-"],
  simpleDots: [".  ", ".. ", "...", "   "],
  simpleDotsScrolling: [".  ", ".. ", "...", " ..", "  .", "   "],
  star: ["✶", "✸", "✹", "✺", "✹", "✷"],
  bounce: ["⠁", "⠂", "⠄", "⠂"],
  arc: ["◜", "◠", "◝", "◞", "◡", "◟"],
  circleQuarters: ["◴", "◷", "◶", "◵"],
  bouncingBar: [
    "[    ]",
    "[=   ]",
    "[==  ]",
    "[=== ]",
    "[ ===]",
    "[  ==]",
    "[   =]",
    "[    ]",
    "[   =]",
    "[  ==]",
    "[ ===]",
    "[====]",
    "[=== ]",
    "[==  ]",
    "[=   ]",
  ],
  bouncingBall: [
    "( ●    )",
    "(  ●   )",
    "(   ●  )",
    "(    ● )",
    "(     ●)",
    "(    ● )",
    "(   ●  )",
    "(  ●   )",
    "( ●    )",
    "(●     )",
  ],
  clock: [
    "🕛",
    "🕐",
    "🕑",
    "🕒",
    "🕓",
    "🕔",
    "🕕",
    "🕖",
    "🕗",
    "🕘",
    "🕙",
    "🕚",
  ],
  christmas: ["🌲", "🎄"],
  point: ["∙∙∙", "●∙∙", "∙●∙", "∙∙●", "∙∙∙"],
  aesthetic: [
    "▰▱▱▱▱▱▱",
    "▰▰▱▱▱▱▱",
    "▰▰▰▱▱▱▱",
    "▰▰▰▰▱▱▱",
    "▰▰▰▰▰▱▱",
    "▰▰▰▰▰▰▱",
    "▰▰▰▰▰▰▰",
    "▰▱▱▱▱▱▱",
  ],
}
